<template>
  <div class="btsb">
    <el-button
      style="position: absolute;top：0; right: 10px"
      type="primary"
      icon="el-icon-arrow-left"
      @click="goback"
      >返回</el-button
    >
    <h1>{{ title }}</h1>
    <!-- <div v-show="activeName == 2">
      申请人：
      <el-input
        style="width: 160px"
        placeholder="请输入内容"
        v-model="input"
        clearable
      >
      </el-input>

      <el-button style="margin-left: 30px" type="primary" @click="search"
        >查询</el-button
      >
    </div> -->
    <!-- <div style="color: #139959; font-weight: bold; margin-top: 20px">
      补贴总户数:126户（单位） <span>总面积:8000亩</span>
    </div> -->
    <table class="btsb-table">
      <tr>
        <td>村(社区)</td>
        <td>补贴总面积(亩)</td>
        <td>补贴普通农户(家庭农场)数</td>
        <td>补贴普通农户(家庭农场)面积(亩)</td>
        <td>补贴专业合作社(农业企业)数</td>
        <td>补贴专业合作社(农业企业)面积(亩)</td>
      </tr>
      <tr>
        <td>{{ identityObj.areaName }}</td>
        <td>{{ normalArea + specialArea }}</td>
        <td>{{ normalFarmers }}</td>
        <td>{{ normalArea }}</td>
        <td>{{ specialFarmers }}</td>
        <td>{{ specialArea }}</td>
      </tr>
    </table>
    <el-tabs
      type="card"
      :stretch="true"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="明细表1（农户、家庭农场）" name="1">
        <div class="scroll-container">
          <table border="1" class="farmers-table">
            <thead>
              <!-- <tr>
              <td colspan="9"><h2>农户名单</h2></td>
            </tr> -->
              <tr>
                <td style="width: 100px !important">村(社区)</td>
                <td style="width: 50px !important">组</td>
                <td style="width: 100px !important">姓名</td>
                <td style="width: 130px !important">身份证号</td>
                <td style="width: 70px !important">家庭（单位）受益人数(人)</td>
                <td style="width: 70px !important">实际种植补贴面积（亩）</td>
                <td style="width: 70px !important">其中承包面积(亩)</td>
                <td style="width: 130px !important">电话号码</td>
                <td style="width: 100px !important">操作</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(farmer, index) in form.farmersList" :key="index">
                <td>
                  <input
                    type="text"
                    v-model="farmer.areaName"
                    style="width: 100px !important"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.group"
                    style="width: 50px !important"
                  />
                </td>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="farmer.content"
                  placement="top"
                  :disabled="farmer.isHave == 'true' ? false : true"
                >
                  <td :class="{ 'error-cell': errorStatus[index].nameError }">
                    <input
                      type="text"
                      v-model="farmer.declareName"
                      @blur="validateInput(farmer.declareName, 'name', index)"
                      style="width: 100px !important"
                    />
                  </td>
                </el-tooltip>

                <td
                  :class="{
                    'error-cell': errorStatus[index].identityError,
                  }"
                  style="width: 130px !important"
                >
                  <input
                    type="text"
                    v-model="farmer.idCard"
                    @blur="validateInput(farmer.idCard, 'id', index)"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.familyPerson"
                    style="width: 70px !important"
                    @blur="usnumber(farmer.familyPerson, 'familyPerson')"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.plantingSubsidyArea"
                    style="width: 70px !important"
                    @blur="
                      usnumber(
                        farmer.plantingSubsidyArea,
                        'plantingSubsidyArea'
                      )
                    "
                  />
                </td>

                <td :class="{ 'error-cell': errorStatus[index].areaError }">
                  <input
                    type="text"
                    v-model="farmer.contractArea"
                    @blur="validateInput(farmer.contractArea, 'area', index)"
                    style="width: 70px !important"
                  />
                </td>

                <td :class="{ 'error-cell': errorStatus[index].phoneError }">
                  <input
                    type="text"
                    v-model="farmer.phone"
                    @blur="validateInput(farmer.phone, 'phone', index)"
                    style="width: 130px !important"
                  />
                </td>
                <td style="width: 100px !important">
                  <el-button type="danger" @click="deleteFarmer(index)"
                    >删除</el-button
                  >
                </td>
              </tr>
            </tbody>
            <tfoot style="text-align: center">
              <tr>
                <td colspan="9">
                  <el-button type="primary" @click="addNFarmers(1)"
                    >添加一行</el-button
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="明细表2
(专业合作社、农业企业)"
        name="2"
      >
        <div class="scroll-container">
          <table border="1" class="farmers-table">
            <thead>
              <tr>
                <td style="width: 100px !important">村(社区)</td>
                <td style="width: 130px !important">企业名称</td>
                <td style="width: 130px !important">企业法人身份证号</td>
                <td style="width: 100px !important">企业法人名称</td>
                <td style="width: 70px !important">企业法人开户名</td>
                <td style="width: 70px !important">企业法人开户行</td>
                <td style="width: 70px !important">企业法人对公账户</td>
                <td style="width: 70px !important">企业下享受补贴人数</td>
                <td style="width: 70px !important">承包种植面积(亩)</td>
                <td style="width: 70px !important">代耕代种面积(亩)</td>
                <td style="width: 70px !important">实际种植补贴面积(亩)</td>
                <td style="width: 130px !important">联系电话</td>
                <td style="width: 100px !important">操作</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(farmer, index) in form.farmersList1" :key="index">
                <td>
                  <input
                    type="text"
                    v-model="farmer.areaName"
                    style="width: 100px !important"
                  />
                </td>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="farmer.content"
                  placement="top"
                  :disabled="farmer.isHave == 'true' ? false : true"
                >
                  <td>
                    <input
                      type="text"
                      v-model="farmer.companyName"
                      style="width: 50px !important"
                    />
                  </td>
                </el-tooltip>
                <td
                  :class="{
                    'error-cell': errorStatus1[index].identityError,
                  }"
                  style="width: 130px !important"
                >
                  <input
                    type="text"
                    v-model="farmer.legalIdCard"
                    @blur="validateInput1(farmer.legalIdCard, 'id', index)"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.legalAccountName"
                    style="width: 100px !important"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.legalName"
                    style="width: 70px !important"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.legalBankName"
                    style="width: 70px !important"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.legalBankCard"
                    style="width: 70px !important"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.enjoySubsidies"
                    style="width: 70px !important"
                    @blur="usnumber(farmer.enjoySubsidies, 'enjoySubsidies')"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.contractArea"
                    style="width: 70px !important"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="farmer.cultivationArea"
                    style="width: 70px !important"
                  />
                </td>

                <td>
                  <input
                    type="text"
                    v-model="farmer.landArea"
                    style="width: 70px !important"
                    @blur="usnumber(farmer.landArea, 'landArea')"
                  />
                </td>

                <td :class="{ 'error-cell': errorStatus1[index].phoneError }">
                  <input
                    type="text"
                    v-model="farmer.phone"
                    @blur="validateInput1(farmer.phone, 'phone', index)"
                    style="width: 130px !important"
                  />
                </td>
                <td style="width: 100px !important">
                  <el-button type="danger" @click="deleteFarmer1(index)"
                    >删除</el-button
                  >
                </td>
              </tr>
            </tbody>
            <tfoot style="text-align: center">
              <tr>
                <td colspan="12">
                  <el-button type="primary" @click="addNFarmers1(1)"
                    >添加一行</el-button
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 相关材料 -->
    <div style="display: flex; text-align: center;margin-top: 20px; margin-bottom: 40px">
      <div style="margin-right: 30px; font-weight: bold">相关材料:</div>
      <div>
        <!-- 图片上传 -->
        <el-upload
          class="upload-demo"
          ref="AvatarUpload"
          action=""
          :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG'"
          list-type="picture-card"
          :auto-upload="false"
          :file-list="fileList"
          :on-change="upLoadImg"
          multiple
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <el-image
              ref="preview"
              style="height: 115px"
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              :preview-src-list="innerTableList"
              alt=""
              :initial-index="BigImgIndex"
            />
            <span>{{ file.name }}</span>
            <span class="el-upload-list__item-actions">
              <span
                v-if="!file.isShow"
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>

              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
    </div>
    <div style="text-align: center">
      <el-button type="primary" :disabled="loadingshow" @click="submit(1)"
        >保存</el-button
      >
      <el-button type="primary" @click="publicity">公示</el-button>
      <el-button type="primary" @click="tijiao">提交审核</el-button>
    </div>

    <el-dialog
      title="审批通过"
      :visible.sync="showy"
      width="20%"
      append-to-body
      :before-close="handleClose"
    >
      <div class="tongyi">
        <div class="topbox" v-if="type == 'cs'">
          <span>审批意见：</span>
          <input type="text" v-model="idea" @input="callApiOnBlur" />
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
              重新生成
            </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { uploadFile } from "@/api/fileApi";
import VueQr from "vue-qr";
import {
  saveSubsidyMainDeclare,
  deleteSubsidyMainDeclare,
  selectFoodPriceDetail,
  publicity,
  commitWorkflow,
  rejectWorkflow_redis
} from "@/api/snbt";
const baseUrl = window.globalUrl.HOME_API;
const Url = window.globalUrl.api1;
const Url1 = window.globalUrl.api2;
import { title } from "process";
import { file } from "jszip";
import { Utils } from "@/assets/js/util.js";
export default {
  name: "补贴申报",
  components: {
    VueQr,
  },
  mixins: [],
  props: {},
  data() {
    return {
      loadingshow: false, // 保存按钮显示
      isHovered: true, //鼠标移入

      // 补贴普通农户(家庭农场)数
      normalFarmers: 0,
      // 补贴普通农户(家庭农场)面积(亩)
      normalArea: 0,
      // 补贴专业合作社(农业企业)数
      specialFarmers: 0,
      // 补贴专业合作社(农业企业)面积(亩)
      specialArea: 0,
      baseUrl: baseUrl,
      title: "", // 页面标题
      subsidyType: "", // 补贴类型
      subsidyId: "", // 补贴id
      activeName: "1", // 激活的选项卡
      errorStatus: [], // 农户列表错误状态
      errorStatus1: [], // 农户列表错误状态
      form: {
        farmersList: [], // 农户列表
        farmersList1: [], // 企业列表
      },
      input: "", // 申请人
      idea: "符合规定，审核通过", // 审批意见
      text: "",
      showy: false, // 审批通过弹窗
      // onshow: false, // 审批不通过弹窗
      fileList: [], //其他材料的文件列表
      innerTableList: [], // 其他材料的图片列表
      BigImgIndex: 0, // 预览大图的索引
      dialogImageUrl: "",
      dialogVisible: false,
      key: "", // 保存时用到的key
      taskKey: "", // 任务key
      status: "", // 状态
      type: "", // 类型
      needSave: false,
      arr: [],
    };
  },
  created() {
    var that = this;
    Utils.$off("dbspDetails");
    Utils.$on("dbspDetails", async function (e) {
      console.log("44454", e.data);
      console.log(JSON.parse(e.data));
      let res = JSON.parse(e.data);
      if (res.isBoolean == true) {
        that.showy = false;
        if (that.type == "cs") {
         await that.uplook();
        }

      await  that.goback();
        // that.gettableData();
      }
    });
    this.title = this.$route.query.title;
    this.subsidyType = this.$route.query.subsidyType;
    this.subsidyId = this.$route.query.subsidyId;
    this.taskKey = this.$route.query.taskKey;
    this.status = this.$route.query.status;
    this.type = this.$route.query.type;
    if (this.type == "cs") {
      this.text = Url1;
    } else if (this.type == "sb") {
      this.text = Url;
    }
    this.gettableData();
    // this.addNFarmers(1);
    // this.addNFarmers1(1);
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {
    "form.farmersList": function (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.needSave = true;
      }
      this.checked1 = this.form.farmersList.length >= 1 ? true : false;
      if (this.form.farmersList.length - 1) {
        for (let i = 0; i < this.form.farmersList.length - 1; i++) {
          this.errorStatus.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        }
      }
    },
    "form.farmersList1": function (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.needSave = true;
      }
      this.checked1 = this.form.farmersList1.length >= 1 ? true : false;
      if (this.form.farmersList1.length - 1) {
        for (let i = 0; i < this.form.farmersList1.length - 1; i++) {
          this.errorStatus1.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        }
      }
    },
  },
  mounted() {},
  methods: {
    //向后端发送合同照片
    async uplook() {
      let params = {
        subsidyId: this.subsidyId,
        files: this.getViewUrl(this.fileList),
      };
      let res = await commitWorkflow(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 公示
    async publicity(row) {
      try {
        const res = await publicity({
          subsidyId: this.subsidyId,
          taskKey: this.type == "cs" ? this.taskKey : "",
        });

        if (res.code === 0) {
          // 使用返回的数据
          this.exportDone(res.data.url, res.data.sourceFileName);
          this.$message.success("公示成功");
        } else {
          this.$message.error(res.msg || "公示失败");
        }
      } catch (error) {
        console.error("公示过程中发生错误:", error);
        this.$message.error("公示过程中发生错误");
      }
    },

    exportDone(url, name) {
      const fileUrl =
        window.globalUrl.HOME_API + "admin/file/get?ossFilePath=" + url;
      const downloadElement = document.createElement("a");
      downloadElement.href = fileUrl;
      downloadElement.download = decodeURIComponent(name);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(fileUrl);
    },

    //  获取粮食明细表
    async gettableData() {
      const res = await selectFoodPriceDetail({
        declareName: this.input,
        areaCode: "",
        status: this.status,
        taskKey: this.status == 0 ? null : this.taskKey,
        subsidyId: this.subsidyId,
      });
      if (res.code == 0) {
        this.fileList = JSON.parse(res.data.data.otherFiles)
          ? this.getViewUrl1(res.data.data.otherFiles)
          : [];
        let arr = [
          {
            declareId: res.data.data.declareId ? res.data.data.declareId : "",
            taskId: res.data.data.taskId ? res.data.data.taskId : "",
          },
        ];
        this.arr = arr;
        this.form.farmersList = res.data.data
          .twActivitySubsidyFoodPriceFarmersList
          ? res.data.data.twActivitySubsidyFoodPriceFarmersList
          : [];
        this.form.farmersList1 = res.data.data
          .twActivitySubsidyFoodPriceCooperativeList
          ? res.data.data.twActivitySubsidyFoodPriceCooperativeList
          : [];
        this.form.farmersList.forEach((item) => {
          // 添加对应的错误状态到 errorStatus 数组
          this.errorStatus.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        });
        this.form.farmersList1.forEach((item) => {
          // 添加对应的错误状态到 errorStatus 数组
          this.errorStatus1.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        });
        this.usnumber1();
      }
      console.log(this.fileList);
    },
    // 计算合
    usnumber1() {
      this.specialFarmers = 0;
      this.specialArea = 0;
      this.normalFarmers = 0;
      this.normalArea = 0;
      this.specialFarmers = this.form.farmersList1
        .map((item) => {
          return Number(item.enjoySubsidies);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.specialFarmers);

      this.specialArea = this.form.farmersList1
        .map((item) => {
          return Number(item.landArea);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.specialArea);

      this.normalArea = this.form.farmersList
        .map((item) => {
          return Number(item.plantingSubsidyArea);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.normalArea);

      this.normalFarmers = this.form.farmersList
        .map((item) => {
          return Number(item.familyPerson);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.normalFarmers);
    },
   async tijiao() {
      if (this.needSave == true && this.key) {
        this.$message.error("请先保存");
        return;
      }
      if(this.type == "cs"){
        await   this.redis();
      }
      
      await this.callApiOnBlur();
      this.showy = true;
  
    },
    // 关闭弹窗
    async handleClose() {
      this.showy = false;
      this.idea = "";
      // let res = await deleteSubsidyMainDeclare({
      //   id: this.key,
      // });
      // this.key = "";
    },
    //上传的图片数组
    getViewUrl(arr) {
      let list = [];
      if (arr) {
        arr.forEach((item) => {
          list.push({
            FilesName: item.name,
            imgUrl: item.url2,
            uid: item.uid,
          });
        });
        return JSON.stringify(list);
      }
    },
    getViewUrl1(arr) {
      let list = [];
      if (arr) {
        JSON.parse(arr).forEach((item) => {
          list.push({
            name: item.FilesName,
            url: baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl,
            url2: item.imgUrl,
            uid: item.uid,
          });
        });
        return list;
      }
    },

    async upLoadImg(file, fileList) {
      this.loadingwxf = true;
      if (file.name.toLowerCase().endsWith("pdf")) {
        // 修改符合条件的文件对象的 url 属性
        file.url =
          "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
        file.isShow = "true";
      }

      let res = await this.upload(file, fileList);
    },
    // 上传
    async upload(file, fileList) {
      let param = new FormData();
      param.append("file", file.raw);
      // 在 axios 请求时传入 signal
      let res;
      res = await uploadFile(param);
      console.log(res, "查看res");

      if (res.code == 0) {
        this.$message.success("上传成功");
      } else {
        fileList = fileList.filter((item) => item.url2 !== file.url2);
        this.$message.error("上传失败");
      }
      file.url2 = res.data.url;

      this.fileList = fileList;
      console.log("file", this.fileList);
    },

    handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
      // console.log(file,'查看file')
      this.BigImgIndex = this.fileList.findIndex(
        (item) => item.uid === file.uid
      );
      let abc = [];
      this.fileList.forEach((its) => {
        if (!its.isShow) {
          abc.push(its.url);
        }
      });
      this.innerTableList = abc;
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
    // 计算合
    usnumber(e, str) {
      if (str == "enjoySubsidies") {
        this.specialFarmers = 0;
        this.specialFarmers = this.form.farmersList1
          .map((item) => {
            return Number(item.enjoySubsidies);
          })
          .reduce((total, current) => {
            return total + current;
          }, this.specialFarmers);
      } else if (str == "landArea") {
        this.specialArea = 0;
        this.specialArea = this.form.farmersList1
          .map((item) => {
            return Number(item.landArea);
          })
          .reduce((total, current) => {
            return total + current;
          }, this.specialArea);
      } else if (str == "plantingSubsidyArea") {
        this.normalArea = 0;
        this.normalArea = this.form.farmersList
          .map((item) => {
            return Number(item.plantingSubsidyArea);
          })
          .reduce((total, current) => {
            return total + current;
          }, this.normalArea);
      } else if (str == "familyPerson") {
        this.normalFarmers = 0;
        this.normalFarmers = this.form.farmersList
          .map((item) => {
            return Number(item.familyPerson);
          })
          .reduce((total, current) => {
            return total + current;
          }, this.normalFarmers);
      }
    },
    // 重新生成二维码
    callApiOnBlur() {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      if (this.type == "cs") {
        this.text = `${Url1}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${this.idea}`;
      } else if (this.type == "sb") {
        this.text = `${Url}?declareId=${
          this.key ? this.key : this.arr[0].declareId
        }/identityId=${identityObj.content.identityId}`;
      }
      console.log(this.text);
    },

    // 拿到key
    async redis() {
      let res = await rejectWorkflow_redis(this.arr);
      if (res.code == 0) {
        this.key = res.data;
      console.log(res.data);
      this.needSave = false;
      } else {
        this.needSave = true;
        this.$message.error(res.msg);
      }
   
      // this.reject(this.obj1);
    },

    // 查询
    search() {
      console.log(this.input);
    },
    // 选项卡切换
    handleClick(tab, event) {
      this.activeName = tab.name;
      //   console.log(tab.name, event);
    },

    // 返回上一级
    goback() {
      this.$router.push({
        path: "/memberCenter/snbt/snbtbl",
      });
    },

    validateInput(value, type, index) {
      if (!value) {
        // 输入内容为空，不进行校验
        // 输入内容为空，将当前框的错误状态设置为 false
        switch (type) {
          case "name":
            this.errorStatus[index].nameError = false;
            break;
          case "area":
            this.errorStatus[index].areaError = false;
            break;
          case "id":
            this.errorStatus[index].identityError = false;
            break;
          case "phone":
            this.errorStatus[index].phoneError = false;
            break;
          default:
          // do nothing
        }
        return;
      }
      let regex;
      switch (type) {
        case "name":
          regex = /^[\u4e00-\u9fa5]+$/;
          break;
        case "area":
          regex = /^\d+(\.\d+)?$/;
          break;
        case "id":
          regex = /^\d{17}(\d|X|x)$/;
          break;
        case "phone":
          regex = /^1[3456789]\d{9}$/;
          break;
        default:
          regex = /.*/; // 默认不校验
      }

      if (!regex.test(value)) {
        switch (type) {
          case "name":
            this.errorStatus[index].nameError = true;
            break;
          case "area":
            this.errorStatus[index].areaError = true;
            break;
          case "id":
            this.errorStatus[index].identityError = true;
            break;
          case "phone":
            this.errorStatus[index].phoneError = true;
            break;
          default:
          // do nothing
        }
      } else {
        switch (type) {
          case "name":
            this.errorStatus[index].nameError = false;
            break;
          case "area":
            this.errorStatus[index].areaError = false;
            break;
          case "id":
            this.errorStatus[index].identityError = false;
            break;
          case "phone":
            this.errorStatus[index].phoneError = false;
            break;
          default:
          // do nothing
        }
      }
    },
    // 添加行
    addNFarmers(n) {
      for (let i = 0; i < n; i++) {
        this.form.farmersList.push({
          areaCode: "",
          areaName: this.identityObj.areaName,
          contractArea: 0,
          declareId: "",
          declareName: "",
          delFlag: "",
          familyPerson: 0,
          group: "",
          idCard: "",
          identityId: "",
          identityType: "",
          phone: "",
          plantingSubsidyArea: 0,
          sign: "",
        });
        // 添加对应的错误状态到 errorStatus 数组
        this.errorStatus.push({
          nameError: false,
          areaError: false,
          identityError: false,
          phoneError: false,
        });
      }
      console.log(this.form.farmersList);
    },
    // 删除行
    deleteFarmer(index) {
      this.form.farmersList.splice(index, 1);
      this.errorStatus.splice(index, 1);
    },

    // 企业添加行
    validateInput1(value, type, index) {
      if (!value) {
        // 输入内容为空，不进行校验
        // 输入内容为空，将当前框的错误状态设置为 false
        switch (type) {
          case "name":
            this.errorStatus1[index].nameError = false;
            break;
          case "area":
            this.errorStatus1[index].areaError = false;
            break;
          case "id":
            this.errorStatus1[index].identityError = false;
            break;
          case "phone":
            this.errorStatus1[index].phoneError = false;
            break;
          default:
          // do nothing
        }
        return;
      }
      let regex;
      switch (type) {
        case "name":
          regex = /^[\u4e00-\u9fa5]+$/;
          break;
        case "area":
          regex = /^\d+(\.\d+)?$/;
          break;
        case "id":
          regex = /^\d{17}(\d|X|x)$/;
          break;
        case "phone":
          regex = /^1[3456789]\d{9}$/;
          break;
        default:
          regex = /.*/; // 默认不校验
      }

      if (!regex.test(value)) {
        switch (type) {
          case "name":
            this.errorStatus1[index].nameError = true;
            break;
          case "area":
            this.errorStatus1[index].areaError = true;
            break;
          case "id":
            this.errorStatus1[index].identityError = true;
            break;
          case "phone":
            this.errorStatus1[index].phoneError = true;
            break;
          default:
          // do nothing
        }
      } else {
        switch (type) {
          case "name":
            this.errorStatus1[index].nameError = false;
            break;
          case "area":
            this.errorStatus1[index].areaError = false;
            break;
          case "id":
            this.errorStatus1[index].identityError = false;
            break;
          case "phone":
            this.errorStatus1[index].phoneError = false;
            break;
          default:
          // do nothing
        }
      }
    },
    // 添加行
    addNFarmers1(n) {
      for (let i = 0; i < n; i++) {
        this.form.farmersList1.push({
          areaCode: "",
          areaName: this.identityObj.areaName,
          companyName: "",
          contractArea: 0,
          cultivationArea: 0,
          declareId: "",
          delFlag: "",
          enjoySubsidies: 0,
          landArea: 0,
          legalAccountName: "",
          legalBankCard: "",
          legalBankName: "",
          legalIdCard: "",
          legalName: "",
          phone: "",
          sign: "",
        });
        // 添加对应的错误状态到 errorStatus 数组
        this.errorStatus1.push({
          nameError: false,
          areaError: false,
          identityError: false,
          phoneError: false,
        });
      }
      console.log(this.form.farmersList1);
    },
    // 删除行
    deleteFarmer1(index) {
      this.form.farmersList1.splice(index, 1);
      this.errorStatus1.splice(index, 1);
    },

    // 保存
    async submit(type) {
      this.loadingshow = true;
      let res = await saveSubsidyMainDeclare({
        subsidyId: this.subsidyId,
        subsidyType: this.subsidyType,
        declareType:this.identityObj.identityTypeCode,
        status: this.type == "cs" ? 2 : 1,
        id: this.arr.length != 0 ? this.arr[0].declareId : "",
        otherFiles: this.getViewUrl(this.fileList),
        twActivitySubsidyFoodPriceCooperativeList: this.form.farmersList1,
        twActivitySubsidyFoodPriceFarmersList: this.form.farmersList,
      });
      if (res.code == 0) {
        this.$message.success("保存成功");
        this.needSave = false;
        this.key = res.data;
        this.callApiOnBlur();
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
      this.loadingshow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.tongyi {
  box-sizing: border-box;
  .topbox {
    span {
      width: 30%;
      font-size: 20px;
      // font-weight: 400;
      color: #000;
    }
    input {
      width: 60%;
      font-size: 20px;
    }
  }

  .footerbox {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
.btsb {
  padding: 30px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .btsb-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    td {
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
    }
  }

  .tongyi {
    box-sizing: border-box;
    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }
      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.error-cell {
  // background-color: lightcoral;
  border-bottom: 3px solid red !important;
}

.scroll-container {
  overflow-x: auto; /* 启用横向滚动 */
  white-space: nowrap; /* 防止内容换行 */
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    thead {
      td {
        font-weight: bold;
        white-space: normal; /* 正常换行 */
      }
    }
    td {
      border: 1px solid #ccc;
      padding: 3px;
      text-align: center;
    }
  }
  input {
    border: none; /* 移除边框 */
    font-size: 16px; /* 设置字体大小为默认大小 */
    // padding: 4px; /* 设置内边距 */
    outline: none; /* 移除选中时的边框 */
    text-align: center;
  }
}
</style>
